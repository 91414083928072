import { IconConfig } from '@design/buttons/icon/icon-config';

const legacyLockedIconConfig: IconConfig = {
  color: 'system-blue',
  hoverColor: 'system-blue',
  backgroundType: 'circle-system-blue-15',
  iconName: 'lock'
};

const legacyStandardIconConfig: IconConfig = {
  color: 'system-green-dark',
  hoverColor: 'system-green-dark',
  backgroundType: 'circle-system-green',
  iconName: 'receipt'
};

const lockedIconConfig: IconConfig = {
  color: 'system-red',
  hoverColor: 'system-red',
  backgroundColor: 'circle-light-red',
  iconName: 'lock'
};

const unlockedIconConfig: IconConfig = {
  color: 'system-blue',
  hoverColor: 'system-blue',
  backgroundColor: 'circle-light-blue',
  iconName: 'lock_open'
};

export function getIconConfig(options: { isPayrollLocked: boolean; allowGroupHandover: boolean; useLegacy: boolean }): IconConfig {
  const { isPayrollLocked, allowGroupHandover, useLegacy } = options;

  if (useLegacy) {
    return isPayrollLocked && allowGroupHandover ? legacyLockedIconConfig : legacyStandardIconConfig;
  } else {
    return isPayrollLocked && allowGroupHandover ? lockedIconConfig : unlockedIconConfig;
  }
}
