import { Inject, Injectable, OnDestroy } from '@angular/core';
import { payrollRoutes } from '@app/payrolls/payrolls-routes';
import { FeatureFlagService, FeatureFlagsServiceInjectionToken } from '@feature-flags/feature-flag.service';
import { LoggingService } from '@logging/logging.service';
import { createStore, select, setProp, withProps } from '@ngneat/elf';
import { ActiveSecurityContextStateService } from '@security/active-security/active-security-context.state-service';
import { createGroupHandoverStatusPillConfig, createPayrollHandoverStatusPillConfig } from '@shared-payrolls';
import { toCurrency } from '@utils/currency-utils';
import { combineLatest } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { EmployerStatistics, EmployerStatisticsState } from './employer-statistics';
import { EmployerStatisticsDataProvider } from './employer-statistics.data-provider';
import { getIconConfig } from './get-icon-config';
import { Summary } from '@design/components/summary-tile/summary';
import { PaymentRunStepsComponent } from '@app/payrolls/payment-runs/steps/payment-run-steps.component';
import { PayrollSummary } from '@app/payrolls/payroll-hub/payroll-statistics/state/payroll-statistics';
import { getLatestPaymentRunByPriority, getReadyForApprovalPaymentRun } from '@app/payrolls/payment-runs/payment-run-utilities';
import { formatDate } from '@utils/date-utils';
import moment from 'moment/moment';

@Injectable()
export class EmployerStatisticsStateService implements OnDestroy {
  private static storeName = 'employer-payroll-stats-models-service';

  private initialValue: EmployerStatisticsState = { employerStatistics: null };

  private readonly store = createStore(
    { name: EmployerStatisticsStateService.storeName },
    withProps<EmployerStatisticsState>(this.initialValue)
  );

  employerPayrollStats$ = this.store.pipe(
    select((q) => q.employerStatistics),
    filter((v) => !!v)
  );

  showGroups = false;

  payrollSummaries$ = combineLatest([
    this.employerPayrollStats$,
    this.featureFlagService.getValue$('allowGroupHandover').pipe(map((x) => !!x)),
    this.featureFlagService.getValue$('multiEmployerDashboard').pipe(map((y) => !y))
  ]).pipe(
    map(([statistics, allowGroupHandover, useLegacyIcons]) => this.toPayrollSummaries(statistics, allowGroupHandover, useLegacyIcons))
  );

  constructor(
    private dataProvider: EmployerStatisticsDataProvider,
    private activeSecurity: ActiveSecurityContextStateService,
    @Inject(FeatureFlagsServiceInjectionToken) private featureFlagService: FeatureFlagService,
    private securityStateService: ActiveSecurityContextStateService,
    private logger: LoggingService
  ) {
    this.featureFlagService.getValue$('allowGroupHandover').subscribe((showGroups) => {
      this.showGroups = !!showGroups;
    });
  }

  fetch(employerId: number) {
    this.dataProvider.readMany$(employerId).subscribe((employerStatistics) => {
      this.logger.trace('EmployerPayrollStatsStateService: data', employerStatistics);
      this.store.update(setProp('employerStatistics', employerStatistics));
    });
  }

  private toPayrollSummaries(employerPayrollStatistics: EmployerStatistics, allowGroupHandover: boolean, useLegacy: boolean): Summary[] {
    const summaries: Summary[] = [];
    employerPayrollStatistics.payrollSummaries.forEach((payrollSummary) => {
      const { unsubmittedGroupCount, submittedGroupCount, isLocked: isPayrollLocked } = payrollSummary;

      let nextPayDayData;
      if (useLegacy) {
        nextPayDayData = this.getNextPayDay(payrollSummary.daysUntilNextPayDay);
      } else {
        nextPayDayData = {
          value: formatDate(payrollSummary.usualPayDate, 'DD MMM YYYY').toString(),
          label: 'Pay day'
        };
      }

      const bureauHandlesBacs = this.securityStateService.activeMembership.bacsProcessor === 'Bureau';

      const statusPillConfig =
        this.activeSecurity.isServiceTypeSource(payrollSummary.id) &&
        this.activeSecurity.hasOrganisationAuthorityToOneOf(['HandoverReOpenPayroll', 'HandoverSubmitPayroll'])
          ? createPayrollHandoverStatusPillConfig({
              isPayrollLocked,
              isOssUser: this.activeSecurity.isOssUser()
            })
          : null;

      const additionalStatusPillConfig =
        allowGroupHandover && (unsubmittedGroupCount > 0 || submittedGroupCount > 0)
          ? createGroupHandoverStatusPillConfig({ unsubmittedGroupCount, submittedGroupCount })
          : null;

      const paymentRunStatus =
        !useLegacy &&
        this.activeSecurity.isServiceTypeSource(payrollSummary.id) &&
        this.activeSecurity.hasOrganisationAuthorityToOneOf(['HandoverReOpenPayroll', 'HandoverSubmitPayroll'])
          ? buildSummaryPayrollHandoverPill(payrollSummary, bureauHandlesBacs)
          : null;

      summaries.push({
        iconConfig: getIconConfig({ isPayrollLocked, allowGroupHandover, useLegacy }),
        routeTo: '/' + payrollRoutes.payrollHub(payrollSummary.id),
        label: payrollSummary.name,
        values: new Map<string, string>([
          ['Active employees', payrollSummary.employeeCount.toString()],
          ['Latest net pay', toCurrency(payrollSummary.netPay, 2, payrollSummary.currency)],
          [nextPayDayData.label, nextPayDayData.value]
        ]),
        employerId: payrollSummary.employerId,
        employerName: payrollSummary.employerName,
        paymentRunStatus,
        statusPillConfig,
        additionalStatusPillConfig,
        unsubmittedGroupCount,
        submittedGroupCount,
        showGroupsCounts: this.showGroups
      });
    });
    return summaries;
  }

  getNextPayDay = (daysTillNextPay: number): { value: string; label: string } => {
    if (daysTillNextPay === 0)
      return {
        value: 'Today',
        label: 'Next pay day'
      };

    if (daysTillNextPay < 0)
      return {
        value: `${Math.abs(daysTillNextPay)} days`,
        label: 'Since last pay day'
      };

    return {
      value: `${daysTillNextPay || '?'} days`,
      label: 'Until next pay day'
    };
  };

  ngOnDestroy(): void {
    this.store.destroy();
  }
}

export const buildSummaryPayrollHandoverPill = (payrollSummary: PayrollSummary, bureauHandlesBacs: boolean) => {
  const paymentRun = getLatestPaymentRunByPriority(payrollSummary.payrollPeriodRuns);

  const handoverComplete = !!paymentRun?.payrollHandoverDate;
  const approvalComplete = !!paymentRun?.payrollApprovedDate;
  const authoriseComplete = !!paymentRun?.paymentAuthorisedDate;

  const awaitingApproval =
    getReadyForApprovalPaymentRun(payrollSummary.payrollPeriodRuns) !== null && payrollSummary.payrollPeriodRuns.isCurrentPeriod;

  const completeByDate = new Date(paymentRun?.authoriseByDate);
  const completeByDateString = isNaN(completeByDate.getTime()) ? undefined : new Date(completeByDate.setHours(15)).toISOString();

  if (
    ((!handoverComplete || !awaitingApproval) && !approvalComplete) ||
    (!payrollSummary.isLocked && approvalComplete && (authoriseComplete || (!approvalComplete && bureauHandlesBacs)))
  ) {
    return PaymentRunStepsComponent.createStepPillConfig({
      step: 'handover',
      isPayrollLocked: payrollSummary.isLocked,
      stepCompleted: handoverComplete,
      actionedDate: paymentRun?.payrollHandoverDate,
      showAwaitingText: !handoverComplete || !payrollSummary.isLocked,
      showActionedByUser: false,
      actionedByUser: paymentRun?.payrollHandoverBy,
      useDarkMode: false,
      shrinkPadding: true,
      rightAlign: true,
      fullWidth: false,
      completeByDate: completeByDateString
    });
  } else if ((handoverComplete && !approvalComplete) || (approvalComplete && !bureauHandlesBacs)) {
    return PaymentRunStepsComponent.createStepPillConfig({
      step: 'approval',
      isPayrollLocked: payrollSummary.isLocked,
      stepCompleted: approvalComplete,
      actionedDate: paymentRun?.payrollApprovedDate,
      showAwaitingText: paymentRun?.payrollHandoverDate && !paymentRun?.payrollApprovedDate,
      showActionedByUser: false,
      actionedByUser: paymentRun?.payrollApprovedBy ?? paymentRun?.payrollHandoverBy,
      useDarkMode: false,
      shrinkPadding: true,
      rightAlign: true,
      fullWidth: false,
      completeByDate: completeByDateString
    });
  } else {
    return PaymentRunStepsComponent.createStepPillConfig({
      step: 'authorisation',
      isPayrollLocked: payrollSummary.isLocked,
      stepCompleted: authoriseComplete,
      actionedDate: paymentRun?.paymentAuthorisedDate,
      showAwaitingText: paymentRun?.payrollApprovedDate && !paymentRun?.paymentAuthorisedDate,
      showActionedByUser: false,
      actionedByUser: paymentRun?.paymentAuthorisedBy ?? paymentRun?.payrollApprovedBy,
      useDarkMode: false,
      shrinkPadding: true,
      rightAlign: true,
      fullWidth: false,
      completeByDate: completeByDateString
    });
  }
};
